var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("TheAside", {
        attrs: { id: _vm.itemId },
        on: { updated: _vm.getAllItems },
      }),
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c(
                "CCardBody",
                { staticClass: "p-2 d-flex flex-column flex-gap-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-lg-row flex-column flex-gap-2",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-100" },
                        [
                          _c("v-select", {
                            staticClass: "v-select-filter",
                            attrs: {
                              placeholder: "Country..",
                              value: _vm.$store.state.filters.country,
                              options: _vm.authCountries,
                              reduce: (c) => c.value,
                              searchable: false,
                            },
                            on: { input: _vm.countryFilter },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-100" },
                        [
                          _c("v-select", {
                            staticClass: "v-select-filter",
                            attrs: {
                              placeholder: "Filter restaurants by..",
                              options: [
                                { value: "active", label: "Active" },
                                { value: "inactive", label: "Inactive" },
                                {
                                  value: "today-closed",
                                  label: "Today closed",
                                },
                                {
                                  value: "temporarily-closed",
                                  label: "Temporarily closed",
                                },
                                { value: "converted", label: "Converted" },
                                { value: "exclusive", label: "Exclusive" },
                                {
                                  value: "unsigned-contract",
                                  label: "Unsigned contract",
                                },
                              ],
                              reduce: (o) => o.value,
                              multiple: "",
                              searchable: false,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setDefaultFilter(false)
                              },
                            },
                            model: {
                              value: _vm.selectedRestaurantsFilter,
                              callback: function ($$v) {
                                _vm.selectedRestaurantsFilter = $$v
                              },
                              expression: "selectedRestaurantsFilter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "input-group flex-nowrap w-100" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "input-group-text select-label",
                              attrs: { for: "resto-and-filter" },
                            },
                            [_vm._v("AND")]
                          ),
                          _c("v-select", {
                            staticClass: "v-select-filter w-100 v-select-label",
                            attrs: {
                              id: "resto-and-filter",
                              placeholder: "Filter restaurants by..",
                              options: [
                                { value: "active", label: "Active" },
                                { value: "inactive", label: "Inactive" },
                                {
                                  value: "today-closed",
                                  label: "Today closed",
                                },
                                {
                                  value: "temporarily-closed",
                                  label: "Temporarily closed",
                                },
                                { value: "converted", label: "Converted" },
                                { value: "exclusive", label: "Exclusive" },
                                {
                                  value: "unsigned-contract",
                                  label: "Unsigned contract",
                                },
                              ],
                              reduce: (o) => o.value,
                              searchable: false,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setDefaultFilter(false)
                              },
                            },
                            model: {
                              value: _vm.selectedRestaurantsANDFilter,
                              callback: function ($$v) {
                                _vm.selectedRestaurantsANDFilter = $$v
                              },
                              expression: "selectedRestaurantsANDFilter",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "fieldset",
                    { staticClass: "border rounded p-3 bg-light" },
                    [
                      _c(
                        "legend",
                        {
                          staticClass:
                            "w-auto bg-white rounded py-1 px-2 resto-tag-title",
                        },
                        [_vm._v(" Restaurant TAG ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-lg-row flex-column flex-gap-2",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "w-100" },
                            [
                              _c("v-select", {
                                staticClass: "v-select-filter-week",
                                attrs: {
                                  placeholder: "-- Choose a period in week --",
                                  options: _vm.periodWeeks,
                                  reduce: (o) => o.value,
                                  searchable: false,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setDefaultFilter(true)
                                  },
                                },
                                model: {
                                  value: _vm.selectedPeriodWeeks,
                                  callback: function ($$v) {
                                    _vm.selectedPeriodWeeks = $$v
                                  },
                                  expression: "selectedPeriodWeeks",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "w-100" },
                            [
                              _c("v-select", {
                                staticClass: "v-select-filter",
                                attrs: {
                                  placeholder: "-- Choose a Restaurant TAG --",
                                  options: _vm.restoTags,
                                  reduce: (o) => o.value,
                                  searchable: false,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setDefaultFilter(false)
                                  },
                                },
                                model: {
                                  value: _vm.selectedRestoTag,
                                  callback: function ($$v) {
                                    _vm.selectedRestoTag = $$v
                                  },
                                  expression: "selectedRestoTag",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "w-100" },
                            [
                              _c("v-select", {
                                staticClass: "v-select-filter",
                                attrs: {
                                  placeholder: "Select Restaurant..",
                                  options: _vm.restaurants,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setDefaultFilter(false)
                                  },
                                },
                                model: {
                                  value: _vm.selectedRestaurantFilter,
                                  callback: function ($$v) {
                                    _vm.selectedRestaurantFilter = $$v
                                  },
                                  expression: "selectedRestaurantFilter",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-lg-row flex-column flex-gap-2",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-100" },
                        [
                          _c("v-select", {
                            staticClass: "v-select-filter",
                            attrs: {
                              placeholder: "Originator..",
                              options: _vm.$globalVar.originators.filter(
                                (o) => o.value !== null
                              ),
                              reduce: (c) => c.value,
                              searchable: false,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setDefaultFilter(false)
                              },
                            },
                            model: {
                              value: _vm.selectedOriginator,
                              callback: function ($$v) {
                                _vm.selectedOriginator = $$v
                              },
                              expression: "selectedOriginator",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-100" },
                        [
                          _c(
                            "v-select",
                            {
                              staticClass: "v-select-filter",
                              attrs: {
                                placeholder: "Location..",
                                label: "zcCity",
                                filterable: false,
                                options: _vm.locations,
                                reduce: (zc) => ({
                                  id: zc.id,
                                  zcZip: zc.zcZip,
                                  zcCity: zc.zcCity,
                                }),
                                loading: _vm.loading,
                                multiple: "",
                              },
                              on: {
                                search: _vm.onSearchLocation,
                                input: function ($event) {
                                  return _vm.setDefaultFilter(false)
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "option",
                                  fn: function (zip_code) {
                                    return [
                                      _c("strong", [
                                        _vm._v(_vm._s(zip_code.zcZip)),
                                      ]),
                                      _vm._v(" - "),
                                      _c("span", [
                                        _vm._v(_vm._s(zip_code.zcCity) + " "),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "selected-option",
                                  fn: function (zip_code) {
                                    return [
                                      _c(
                                        "strong",
                                        { staticClass: "text-dark mr-1" },
                                        [_vm._v(_vm._s(zip_code.zcZip))]
                                      ),
                                      _c("span", [
                                        _vm._v(_vm._s(zip_code.zcCity) + " "),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.selectedLocations,
                                callback: function ($$v) {
                                  _vm.selectedLocations = $$v
                                },
                                expression: "selectedLocations",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v("Type to get locations..")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "w-100" },
                        [
                          _c("CInput", {
                            staticClass: "mb-0",
                            attrs: {
                              type: "search",
                              placeholder:
                                "Type i#Id for Restaurant, u#Id for User, v#XX for VAT or anything else ...",
                            },
                            on: { input: _vm.searchFilter },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          size: "sm",
          "clickable-rows": "",
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No restaurants found!",
          },
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "row-clicked": _vm.rowClicked,
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "select",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "no-print" },
                  [
                    _c("CInputCheckbox", {
                      attrs: { checked: item._selected, custom: "" },
                      on: { "update:checked": () => _vm.check(item) },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "id",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showAside(item.id)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.id) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "prev_rest_next_rest",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "no-print" },
                  [
                    item.prev_rest
                      ? _c(
                          "CLink",
                          {
                            staticClass: "d-inline count bg-dark px-1",
                            on: {
                              click: function ($event) {
                                return _vm.showAside(item.prev_rest)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.prev_rest))]
                        )
                      : _c("b", [_vm._v("∅")]),
                    _vm._v(" / "),
                    item.next_rest
                      ? _c(
                          "CLink",
                          {
                            staticClass: "d-inline count bg-dark px-1",
                            on: {
                              click: function ($event) {
                                return _vm.showAside(item.next_rest)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.next_rest))]
                        )
                      : _c("b", [_vm._v("∅")]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "restaurant_device",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "no-print" },
                  [
                    _c("span", {
                      staticClass:
                        "badge notify badge-secondary border border-dark",
                      domProps: {
                        innerHTML: _vm._s(_vm.checkDeviceType(item)),
                      },
                    }),
                    _c("CIcon", {
                      directives: [
                        {
                          name: "c-tooltip",
                          rawName: "v-c-tooltip",
                          value: {
                            html: true,
                            content: _vm.checkLinkedDeviceLastCheckTime(item),
                          },
                          expression:
                            "{\n            html: true,\n            content: checkLinkedDeviceLastCheckTime(item)\n          }",
                        },
                      ],
                      class: _vm.checkLinkedDevice(item),
                      attrs: { height: 32, name: "cil-mobile" },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "restaurant_logo",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showAside(item.id)
                          },
                        },
                      },
                      [
                        _c("CImg", {
                          staticClass: "c-avatar-img",
                          staticStyle: { width: "36px", height: "36px" },
                          attrs: {
                            src: _vm.getValidImageUrl(
                              item.restaurant_logo_path
                            ),
                            placeholderColor: "lightgray",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "restaurant_opening_hours",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center no-print" },
                  [
                    _c("CIcon", {
                      directives: [
                        {
                          name: "c-tooltip",
                          rawName: "v-c-tooltip",
                          value: {
                            html: true,
                            content: _vm.getRestaurantOpeningTime(item),
                          },
                          expression:
                            "{\n            html: true,\n            content: getRestaurantOpeningTime(item)\n          }",
                        },
                      ],
                      class: _vm.isRestaurantOpenNow(item)
                        ? "text-success"
                        : "text-danger",
                      attrs: { height: 32, name: "cil-clock" },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "restaurant_name",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.restaurant_slug,
                            expression: "item.restaurant_slug",
                          },
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: { content: item.restaurant_slug_path },
                            expression: "{content: item.restaurant_slug_path}",
                          },
                        ],
                        attrs: {
                          href: _vm._f("addUriScheme")(
                            item.restaurant_slug_path
                          ),
                          target: "_blank",
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-restaurant" } })],
                      1
                    ),
                    _vm._v("   "),
                    _c(
                      "CLink",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showAside(item.id)
                          },
                        },
                      },
                      [_c("strong", [_vm._v(_vm._s(item.restaurant_name))])]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "restaurant_postcode",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c("strong", [_vm._v(_vm._s(item.restaurant_city))]),
                  _c("small", { staticClass: "d-block" }, [
                    _vm._v(_vm._s(item.restaurant_postcode)),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "user_name",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c(
                      "CLink",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: { content: `# ${item.user.id}` },
                            expression: "{content : `# ${item.user.id}`}",
                          },
                        ],
                        attrs: {
                          to: { name: "User", params: { id: item.user.id } },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.user.first_name) + " ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "account_balance",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  {
                    staticClass: "text-center no-print",
                    class: item.account
                      ? item.account.balance >= 0
                        ? "text-danger"
                        : "text-success"
                      : "",
                  },
                  [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            item.account ? item.account.balance : "∅"
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "menu_count_category_count",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "no-print" },
                  [
                    _c(
                      "CLink",
                      { staticClass: "d-inline count bg-dark px-1" },
                      [_vm._v(_vm._s(item.menus_count))]
                    ),
                    _vm._v(" / "),
                    _c(
                      "CLink",
                      { staticClass: "d-inline count bg-dark px-1" },
                      [_vm._v(_vm._s(item.categories_count))]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "restaurant_orders_count",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "no-print" },
                  [
                    _c("CLink", { staticClass: "count bg-dark w-75 px-1" }, [
                      _vm._v(_vm._s(item.restaurant_orders_count)),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "menu_active_relation_active",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center no-print" },
                  [
                    _c(
                      "CBadge",
                      {
                        staticClass: "badge-first",
                        attrs: {
                          color: item.menu_active == 1 ? "success" : "danger",
                        },
                      },
                      [_vm._v(_vm._s(item.menu_active == 1 ? "Yes" : "No"))]
                    ),
                    _c(
                      "CBadge",
                      {
                        attrs: {
                          color:
                            item.relation_active == 1 ? "success" : "danger",
                        },
                      },
                      [_vm._v(_vm._s(item.relation_active == 1 ? "Yes" : "No"))]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "subweb",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center no-print" },
                  [
                    _c(
                      "CLink",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.website_etenonline_be,
                            expression: "item.website_etenonline_be",
                          },
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: { content: item.website_etenonline_be },
                            expression: "{content: item.website_etenonline_be}",
                          },
                        ],
                        attrs: {
                          href: _vm._f("addUriScheme")(
                            item.website_etenonline_be
                          ),
                          target: "_blank",
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-external-link" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "text-center no-print" },
                  [
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              content: "Edit",
                              placement: "top",
                            },
                            expression:
                              "{\n            content: 'Edit',\n            placement: 'top',\n          }",
                          },
                        ],
                        attrs: {
                          to: {
                            name: "Edit Restaurant",
                            params: { id: item.id },
                          },
                          size: "sm",
                          color: "info",
                          variant: "ghost",
                          shape: "pill",
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-align-left" } })],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        directives: [
                          {
                            name: "c-tooltip",
                            rawName: "v-c-tooltip",
                            value: {
                              content: "View",
                              placement: "top",
                            },
                            expression:
                              "{\n            content: 'View',\n            placement: 'top',\n          }",
                          },
                        ],
                        attrs: {
                          to: {
                            name: "View Restaurant",
                            params: { id: item.id },
                          },
                          size: "sm",
                          color: "primary",
                          variant: "ghost",
                          shape: "pill",
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-restaurant" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "CModal",
        {
          staticClass: "copy-restaurant",
          attrs: {
            title: "Copy Restaurant Features",
            color: "info",
            show: _vm.copyFeaturesModal,
            closeOnBackdrop: false,
            centered: "",
          },
          on: {
            "update:show": function ($event) {
              _vm.copyFeaturesModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.copyFeaturesModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        disabled: _vm.selectedRestaurant === null,
                        size: "sm",
                        color: "info",
                      },
                      on: { click: _vm.copyFeatures },
                    },
                    [
                      _c("CIcon", {
                        attrs: { name: "cil-double-quote-sans-right" },
                      }),
                      _vm._v(" Copy Features "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "CAlert",
            { staticClass: "p-2", attrs: { show: "", color: "warning" } },
            [
              _c("CIcon", {
                staticClass: "mr-2",
                attrs: { height: 24, name: "cil-warning" },
              }),
              _c("span", [
                _vm._v(
                  "This operation will copy the category, menu and menu templates of restaurant "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.selectedItemLabel))]),
                _vm._v(" to the "),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.selectedRestaurant
                        ? _vm.selectedRestaurant.label
                        : "target"
                    )
                  ),
                ]),
                _vm._v(" restaurant. "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "label",
                {
                  staticClass: "d-block",
                  staticStyle: { "font-weight": "500" },
                },
                [_vm._v("Target Restaurant:")]
              ),
              _c("v-select", {
                staticClass: "v-select-filter",
                attrs: {
                  placeholder: "Select restaurant..",
                  options: _vm.allRestaurants,
                },
                model: {
                  value: _vm.selectedRestaurant,
                  callback: function ($$v) {
                    _vm.selectedRestaurant = $$v
                  },
                  expression: "selectedRestaurant",
                },
              }),
            ],
            1
          ),
          _c("CInputCheckbox", {
            attrs: {
              label: "Also copy menu images",
              custom: "",
              checked: _vm.copyMenuImages,
            },
            on: {
              "update:checked": function ($event) {
                _vm.copyMenuImages = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "CModal",
        {
          staticClass: "copy-restaurant",
          attrs: {
            title: `${_vm.$options.filters.ucfirst(_vm.copyEvent)} Restaurant`,
            color: "info",
            show: _vm.copyRestaurantModal,
            closeOnBackdrop: false,
            centered: "",
          },
          on: {
            "update:show": function ($event) {
              _vm.copyRestaurantModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.copyRestaurantModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { size: "sm", color: "info" },
                      on: {
                        click: function ($event) {
                          return _vm.copyRestaurant(_vm.copyEvent + "d")
                        },
                      },
                    },
                    [
                      _c("CIcon", {
                        attrs: {
                          name: `cil-${
                            _vm.copyEvent == "clone" ? "clone" : "copy"
                          }`,
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("ucfirst")(_vm.copyEvent)) +
                          " Restaurant "
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "CAlert",
            { staticClass: "p-2", attrs: { show: "", color: "warning" } },
            [
              _c("CIcon", {
                staticClass: "mr-2",
                attrs: { height: 24, name: "cil-warning" },
              }),
              _c("span", [
                _vm._v(" The restaurant will be "),
                _c("strong", [_vm._v(_vm._s(_vm.copyEvent) + "d")]),
                _vm._v(". Do you really want to continue? "),
              ]),
            ],
            1
          ),
          _c("CInputCheckbox", {
            attrs: {
              label: "Also copy menu images",
              custom: "",
              checked: _vm.copyMenuImages,
            },
            on: {
              "update:checked": function ($event) {
                _vm.copyMenuImages = $event
              },
            },
          }),
        ],
        1
      ),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "CDropdown",
                      {
                        attrs: {
                          color: "success",
                          size: "sm",
                          disabled: _vm.selectedItem === null,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "toggler-content",
                            fn: function () {
                              return [
                                _c("CIcon", { attrs: { name: "cil-copy" } }),
                                _vm._v(" Copy Restaurant "),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c(
                          "CDropdownItem",
                          {
                            on: {
                              click: () => {
                                _vm.copyEvent = "replicate"
                                _vm.copyRestaurantModal = true
                              },
                            },
                          },
                          [
                            _c("CIcon", {
                              staticClass: "mt-1 mr-1",
                              attrs: { name: "cil-copy" },
                            }),
                            _vm._v("Replicate (new btw) "),
                          ],
                          1
                        ),
                        _c(
                          "CDropdownItem",
                          {
                            on: {
                              click: () => {
                                _vm.copyEvent = "clone"
                                _vm.copyRestaurantModal = true
                              },
                            },
                          },
                          [
                            _c("CIcon", {
                              staticClass: "mt-1 mr-1",
                              attrs: { name: "cil-clone" },
                            }),
                            _vm._v("Clone (multiple same resto) "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          color: "info",
                          size: "sm",
                          disabled: _vm.selectedItem === null,
                        },
                        on: {
                          click: function ($event) {
                            _vm.copyFeaturesModal = true
                          },
                        },
                      },
                      [
                        _c("CIcon", {
                          attrs: { name: "cil-double-quote-sans-right" },
                        }),
                        _vm._v(" Copy Restaurant Features "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.8, mtop: 32 + _vm.mcSpinnerMarginTop + "px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }