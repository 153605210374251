var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { sm: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-restaurant" } }),
                  _c("h5", { staticClass: "d-inline ml-2" }, [
                    _vm._v("Restaurants"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-header-actions" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: {
                            color: "primary",
                            to: { name: "RestaurantCreationWizard" },
                          },
                        },
                        [
                          _c("CIcon", {
                            staticClass: "align-bottom",
                            attrs: { name: "cil-plus" },
                          }),
                          _c("strong", [_vm._v("Add New")]),
                          _vm._v(" Restaurant "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("BackendTable"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }