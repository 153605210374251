var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.restaurantsAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["restaurantsAsideShow", val]),
      },
    },
    [
      _c(
        "div",
        { staticClass: "scrollable" },
        [
          _c("div", { staticClass: "d-flex my-1" }, [
            !_vm.showErrorAlert
              ? _c("div", { staticClass: "flex-grow-1" }, [
                  _c(
                    "div",
                    { staticClass: "logo-container" },
                    [
                      typeof _vm.item.id !== "undefined"
                        ? _c(
                            "CLink",
                            {
                              attrs: {
                                to: {
                                  name: "View Restaurant",
                                  params: { id: _vm.item.id },
                                },
                                target: "_blank",
                              },
                            },
                            [
                              _c("CImg", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.item.restaurant_logo,
                                    expression: "item.restaurant_logo",
                                  },
                                ],
                                staticClass: "rounded mx-auto restaurant-logo",
                                attrs: {
                                  src: _vm.getValidImageUrl(
                                    _vm.item.restaurant_logo_path
                                  ),
                                  placeholderColor: "lightgray",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              [
                _c("CSidebarClose", {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$store.commit("toggle", "restaurantsAsideShow")
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.showErrorAlert
            ? _c(
                "CListGroup",
                { staticClass: "list-group-accent mt-5" },
                [
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase",
                    },
                    [_vm._v(" Error ")]
                  ),
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-danger list-group-item-divider",
                      attrs: { href: "#" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "c-avatar float-left mr-2" },
                        [
                          _c("CIcon", {
                            staticClass: "c-avatar-img text-danger",
                            attrs: { name: "cil-bug", size: "xl" },
                          }),
                        ],
                        1
                      ),
                      _c("div", [_vm._v(_vm._s(_vm.errorAlertMessage))]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.showErrorAlert
            ? _c(
                "CListGroup",
                { staticClass: "list-group-accent" },
                [
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.item.restaurant_name) +
                                " (#" +
                                _vm._s(_vm.item.id) +
                                ")"
                            ),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        // content: this.$router.resolve({name: 'View Restaurant',params: { id: item.id }}).href,
                                        content: "View",
                                        placement: "left",
                                      },
                                      expression:
                                        "{\n              // content: this.$router.resolve({name: 'View Restaurant',params: { id: item.id }}).href,\n              content: 'View',\n              placement: 'left',\n            }",
                                    },
                                  ],
                                  attrs: {
                                    target: "_blank",
                                    size: "sm",
                                    color: "primary",
                                    shape: "pill",
                                    to: {
                                      name: "View Restaurant",
                                      params: { id: _vm.item.id },
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-restaurant" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        content: "Edit",
                                        placement: "right",
                                      },
                                      expression:
                                        "{\n              content: 'Edit',\n              placement: 'right',\n            }",
                                    },
                                  ],
                                  staticClass: "ml-2",
                                  attrs: {
                                    size: "sm",
                                    color: "info",
                                    shape: "pill",
                                    to: {
                                      name: "Edit Restaurant",
                                      params: { id: _vm.item.id },
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-align-left" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.item && !_vm.item.upload_contract
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-warning table-warning p-0",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("UnsignedContract", {
                                attrs: {
                                  id: _vm.item.id,
                                  email: _vm.item.email,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.item.hasdeliveryService
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-success table-success p-0",
                        },
                        [
                          _c(
                            "CAlert",
                            {
                              staticClass:
                                "d-flex flex-gap-2 align-items-center mb-0 h-100",
                              attrs: { color: "success" },
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-truck", size: "2xl" },
                              }),
                              _c("div", [
                                _c("strong", [
                                  _vm._v("Delivery Service enabled."),
                                ]),
                                !_vm.item.hubs_count
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-gap-2 align-items-center text-danger font-weight-bold flash-it",
                                      },
                                      [
                                        _vm._v(
                                          " ⚠ Add the postcode (" +
                                            _vm._s(
                                              _vm.item.restaurant_postcode
                                            ) +
                                            ") to the scope of a hub. "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "CListGroupItem",
                    {
                      class: _vm.item.none_active
                        ? "list-group-item-accent-danger table-danger"
                        : "list-group-item-accent-success table-success",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("CIcon", {
                            staticClass: "mr-2",
                            attrs: { name: "cil-arrow-right" },
                          }),
                          _c("strong", { staticClass: "text-muted mr-2" }, [
                            _vm._v("Not Published"),
                          ]),
                          _c("CSwitch", {
                            attrs: {
                              checked: _vm.item.none_active,
                              labelOn: "YES",
                              labelOff: "NO",
                              color: "danger",
                              shape: "pill",
                              size: "lg",
                            },
                            on: {
                              "update:checked": [
                                function ($event) {
                                  return _vm.$set(
                                    _vm.item,
                                    "none_active",
                                    $event
                                  )
                                },
                                _vm.updateRestaurantStatus,
                              ],
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-accent-info list-group-item-divider",
                    },
                    [
                      _c("div", [_c("strong", [_vm._v("Device Link")])]),
                      _c(
                        "CRow",
                        [
                          _c("CCol", { attrs: { col: "12" } }, [
                            _c(
                              "h6",
                              { staticClass: "text-muted mr-3" },
                              [
                                _c("CIcon", {
                                  staticClass: "mr-2",
                                  attrs: { name: "cil-arrow-right" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      this.linkedDevice
                                        ? this.linkedDevice.sid
                                        : "No device linked yet!"
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm.linkedDevice
                            ? _c(
                                "CCol",
                                { attrs: { col: "12" } },
                                [
                                  _c(
                                    "CButton",
                                    {
                                      attrs: {
                                        color: "danger",
                                        size: "sm",
                                        variant: "ghost",
                                        shape: "pill",
                                      },
                                      on: { click: _vm.unlinkDevice },
                                    },
                                    [
                                      _c("CIcon", { attrs: { name: "cil-x" } }),
                                      _vm._v(" Unlink Device "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "CCol",
                                {
                                  staticClass: "border-shadow p-2",
                                  attrs: { col: "12" },
                                },
                                [
                                  _c(
                                    "CTabs",
                                    {
                                      attrs: { "active-tab": _vm.activeTab },
                                      on: {
                                        "update:activeTab": function ($event) {
                                          _vm.activeTab = $event
                                        },
                                        "update:active-tab": function ($event) {
                                          _vm.activeTab = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm.previousRestDevice
                                        ? _c(
                                            "CTab",
                                            {
                                              staticClass: "aside-tab-item",
                                              attrs: {
                                                title: "Get From the Previous",
                                              },
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "py-2" },
                                                [
                                                  _c("li", [
                                                    _vm._v(
                                                      "Previous Restaurant ID: #" +
                                                        _vm._s(
                                                          _vm.previousRestDevice
                                                            .restaurant_id
                                                        )
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _vm._v(
                                                      "Device ID: #" +
                                                        _vm._s(
                                                          _vm.previousRestDevice
                                                            .device?.device?.sid
                                                        )
                                                    ),
                                                  ]),
                                                  _c("li", [
                                                    _vm._v(
                                                      "Software: " +
                                                        _vm._s(
                                                          _vm.previousRestDevice
                                                            .device?.software
                                                            ? "YES"
                                                            : "NO"
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "CButton",
                                                {
                                                  attrs: {
                                                    type: "submit",
                                                    color: "success",
                                                    size: "sm",
                                                    shape: "pill",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.linkDevice(
                                                        $event,
                                                        _vm.previousRestDevice
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: { name: "cil-pin" },
                                                  }),
                                                  _vm._v(" Link Device "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "CTab",
                                        {
                                          staticClass: "aside-tab-item",
                                          attrs: { title: "Select New" },
                                        },
                                        [
                                          _c(
                                            "CForm",
                                            {
                                              staticClass: "pt-1",
                                              on: { submit: _vm.linkDevice },
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "mb-3",
                                                attrs: {
                                                  options: _vm.availableDevices,
                                                  label: "sid",
                                                  placeholder:
                                                    "Please choose a device..",
                                                  searchable: true,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "search",
                                                      fn: function ({
                                                        attributes,
                                                        events,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "input",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "vs__search",
                                                                  attrs: {
                                                                    required:
                                                                      !_vm.selectedDevice,
                                                                  },
                                                                },
                                                                "input",
                                                                attributes,
                                                                false
                                                              ),
                                                              events
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3166129734
                                                ),
                                                model: {
                                                  value: _vm.selectedDevice,
                                                  callback: function ($$v) {
                                                    _vm.selectedDevice = $$v
                                                  },
                                                  expression: "selectedDevice",
                                                },
                                              }),
                                              _c(
                                                "CButton",
                                                {
                                                  attrs: {
                                                    type: "submit",
                                                    color: "success",
                                                    size: "sm",
                                                    shape: "pill",
                                                  },
                                                },
                                                [
                                                  _c("CIcon", {
                                                    attrs: { name: "cil-pin" },
                                                  }),
                                                  _vm._v(" Link Device "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "RestaurantPaymentList",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [
                            _c("strong", [_vm._v("Account Balance")]),
                          ]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(_vm._s(_vm.item.account_balance) + " "),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "RestaurantOrderList",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [_c("strong", [_vm._v("Orders")])]),
                          _vm._v("> "),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(
                                _vm._s(_vm.item.restaurant_orders_count) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "RestaurantReviews",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [_c("strong", [_vm._v("Reviews")])]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(
                                _vm._s(_vm.item.restaurant_reviews_count) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "RestaurantLinkedZIPCodes",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [_c("strong", [_vm._v("Zip Codes")])]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(
                                _vm._s(
                                  _vm.item.zip_codes_linked_restaurants_count
                                ) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "RestaurantLinkedTypes",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [_c("strong", [_vm._v("Linked Types")])]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(
                                _vm._s(_vm.item.restaurant_linked_types_count) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "RestaurantLinkedCategories",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [_c("strong", [_vm._v("Categories")])]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(_vm._s(_vm.item.categories_count) + " "),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "RestaurantLinkedMenus",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [_c("strong", [_vm._v("Menus")])]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(_vm._s(_vm.item.menus_count) + " "),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "Restaurant Menu Sjabloons",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [_c("strong", [_vm._v("Menu Sjabloons")])]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(
                                _vm._s(_vm.item.menu_sjabloons_count) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "RestaurantTaskList",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [_c("strong", [_vm._v("Tasks")])]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.item.open_tasks_count) +
                                  " / " +
                                  _vm._s(_vm.item.tasks_count) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  typeof _vm.item.id !== "undefined"
                    ? _c(
                        "CListGroupItem",
                        {
                          staticClass:
                            "list-group-item-accent-dark list-group-item-divider",
                          attrs: {
                            to: {
                              name: "RestaurantPermissionList",
                              params: { restaurant_id: _vm.item.id },
                            },
                          },
                        },
                        [
                          _c("div", [_c("strong", [_vm._v("Permissions")])]),
                          _c(
                            "h4",
                            { staticClass: "text-muted mr-3" },
                            [
                              _c("CIcon", {
                                staticClass: "mr-2",
                                attrs: { name: "cil-arrow-right" },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.item.permissions_count) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c("CElementCover", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            attrs: { opacity: 0.2 },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }